<template>
  <div class="reg">
    <div class="reg-header">
      <topNav></topNav>
    </div>
    <div class="reg-main">
      <div class="m-steps">
        <Steps :current="curIndex">
          <Step title="验证手机"></Step>
          <Step title="基本信息"></Step>
          <Step title="完成注册"></Step>
        </Steps>
      </div>

      <div class="admin-add" v-if="phone">
        <div class="admin-add-content">
          <Form class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
            <FormItem label="姓名：" :label-width="260" prop="name">
              <Input v-model="formInline.name" type="text" placeholder="请输入姓名" style="width: 300px" />
            </FormItem>
            <FormItem label="密码：" :label-width="260" v-if="user && user.userId">
              <Input value="******" type="text" placeholder="请输入密码" style="width: 300px" maxlength="20" minlength="6" disabled />
            </FormItem>
            <FormItem label="密码：" :label-width="260" v-else prop="passWord">
              <Input v-model="formInline.passWord" type="text" placeholder="请输入密码" style="width: 300px" maxlength="20" minlength="6" />
            </FormItem>
            <FormItem label="手机：" :label-width="260" prop="phone">
              <Input v-model="formInline.phone" disabled type="text" placeholder="请输入手机号码" style="width: 300px" />
            </FormItem>
            <FormItem label="邮箱：" :label-width="260" prop="email">
              <Input v-model="formInline.email" type="text" placeholder="请输入邮箱" style="width: 300px" />
            </FormItem>
            <!-- <FormItem label="验证码：" :label-width="260" class="service-code" prop="code">
                  <Input
                    v-model="formInline.code"
                    type="text"
                    placeholder="请输入验证码"
                    style="width: 300px;margin-right:20px"
                  />
                  <Button
                    v-if="showCode"
                    @click="getCaptcha"
                    class="code-btn1"
                    >获取验证码</Button
                  >
                  <Button
                    v-else
                    disabled
                    class="code-btn2"
                    style="color: #ccc"
                    >{{ count }}s后重新获取</Button
                  >
                </FormItem> -->
            <FormItem label="公司：" :label-width="260" prop="company">
              <Input v-model="formInline.company" type="text" placeholder="请输入公司名称" style="width: 300px" />
            </FormItem>
            <FormItem label="职务：" :label-width="260" prop="jobTitle">
              <Input v-model="formInline.jobTitle" type="text" placeholder="请输入职务" style="width: 300px" />
            </FormItem>
            <FormItem label="城市：" :label-width="260" prop="city">
              <Cascader :data="areaList" v-model="formInline.city" style="width: 300px"></Cascader>
            </FormItem>
            <FormItem label="感兴趣类别:" :label-width="260" prop="category" v-if="categoryList">
              <Row v-for="(item, index) in formInline.category" :key="cateKey + '' + index" style="margin-bottom: 5px">
                <Col span="12">
                <Cascader v-model="formInline.category[index]" filterable :data="categoryList" :key="'categoryArr' + index" :ref="'categoryArr' + index" @on-change="changeCategoryArr()"></Cascader>
                </Col>
                <Col span="2" style="text-align: center">
                <Icon type="ios-add-circle-outline" v-if="index === 0" @click="addCategoryArrr()" style="color: #2d8cf0; font-size: 20px; cursor: pointer" />
                <Icon type="ios-remove-circle-outline" v-else style="color: red; font-size: 20px; cursor: pointer" @click="delCategoryArrr(index)" />
                </Col>
              </Row>
            </FormItem>

            <FormItem :label-width="260">
              <Button type="primary" @click="handleSubmit('formInline')" size="large" style="width: 300px" class="btn">下一步</Button>
            </FormItem>
          </Form>
        </div>
      </div>
      <div class="admin-add" v-else>
        <div class="admin-add-content">
          <Form class="F" ref="formInline" :model="formInline" :rules="ruleInline" @keyup.enter.native="handleSubmit('formInline')">
            <FormItem label="姓名：" :label-width="260" prop="name">
              <Input v-model="formInline.name" type="text" placeholder="请输入姓名" style="width: 300px" />
            </FormItem>
            <FormItem label="手机：" :label-width="260" prop="phone">
              <Input v-model="formInline.phone" type="text" placeholder="请输入手机号码" style="width: 300px" />
            </FormItem>

            <FormItem :label-width="260">
              <div class="service-code">
                <Input type="text" v-model="numCode" :placeholder="'请输入手机验证码'" style="width: 300px"> </Input>
                <Button type="text" v-if="showCode" @click="getCaptcha()" class="code-btn1">发送验证码</Button>
                <Button type="text" v-else disabled class="code-btn2" style="color: #ccc">{{ count }}s后重新获取</Button>
              </div>
            </FormItem>
            <!-- <FormItem
              label="验证码："
              :label-width="260"
              class="service-code"
              prop="code"
            >
              <Input
                v-model="formInline.code"
                type="text"
                placeholder="请输入验证码"
                style="width: 300px; margin-right: 20px"
              />
              <Button v-if="showCode" @click="getCaptcha" class="code-btn1"
                >获取验证码</Button
              >
              <Button v-else disabled class="code-btn2" style="color: #ccc"
                >{{ count }}s后重新获取</Button
              >
            </FormItem> -->
            <FormItem label="公司：" :label-width="260" prop="company">
              <Input v-model="formInline.company" type="text" placeholder="请输入公司名称" style="width: 300px" />
            </FormItem>
            <FormItem label="职务：" :label-width="260" prop="jobTitle">
              <Input v-model="formInline.jobTitle" type="text" placeholder="请输入职务" style="width: 300px" />
            </FormItem>
            <FormItem label="城市：" :label-width="260" prop="city">
              <Cascader :data="areaList" v-model="formInline.city" style="width: 300px"></Cascader>
            </FormItem>
            <FormItem label="感兴趣类别:" :label-width="260" prop="category" v-if="categoryList">
              <Row v-for="(item, index) in formInline.category" :key="cateKey + '' + index" style="margin-bottom: 5px">
                <Col span="12">
                <Cascader v-model="formInline.category[index]" filterable :data="categoryList" :key="'categoryArr' + index" :ref="'categoryArr' + index" @on-change="changeCategoryArr()"></Cascader>
                </Col>
                <Col span="2" style="text-align: center">
                <Icon type="ios-add-circle-outline" v-if="index === 0" @click="addCategoryArrr()" style="color: #2d8cf0; font-size: 20px; cursor: pointer" />
                <Icon type="ios-remove-circle-outline" v-else style="color: red; font-size: 20px; cursor: pointer" @click="delCategoryArrr(index)" />
                </Col>
              </Row>
            </FormItem>

            <FormItem :label-width="260">
              <Button type="primary" @click="handleSubmit('formInline')" size="large" style="width: 300px" class="btn">下一步</Button>
            </FormItem>
          </Form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { validator } from "@/other/validator";
import topNav from "./components/top";
import provincial from "@/assets/js/provinces.json"; //省市区json

export default {
  name: "reg",
  data () {
    return {
      code: "",
      cateKey: 0,
      numCode: "", // 手机验证码
      timer: null,
      showCode: true,
      loading: false,
      count: "",
      codeKey: "",

      categoryList: [],
      curIndex: 1,

      formInline: {
        name: "1231",
        passWord: "12312",
        phone: "123123",
        code: "",
        company: "",
        jobTitle: "",
        city: [],
        category: [],
        email: "",
      },
      ruleInline: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        passWord: [
          { required: true, message: "密码不能为空", trigger: "blur" },
          { type: "string", max: 20, message: "最多20位", trigger: "blur" },
          { type: "string", min: 6, message: "最少6位", trigger: "blur" },
        ],
        phone: validator.phone,
        code: [{ required: true, message: "验证码不能为空", trigger: "blur" }],
        company: [{ required: true, message: "公司不能为空", trigger: "blur" }],
        jobTitle: [{ required: true, message: "职位不能为空", trigger: "blur" }],
        city: [
          {
            required: true,
            type: "array",
            message: "城市不能为空",
            trigger: "change",
          },
        ],
      },
      count: "",
      captchaImg: "",
      userMsg: "",
      showModel: false,
      areaList: [], //地址数据
      categoryList: [], // 类别数据
    };
  },
  components: {
    topNav,
  },
  computed: {
    ...mapGetters({
      exhibitionId: "getExhibitionId",
      regOrigin: "getRegOrigin",
      user: "getUser",
      token: "getToken",
    }),
    isCover () {
      return this.$route.query.isCover || false;
    },
    phone () {
      return this.$route.query.phone;
    },
  },
  created () {
    console.log(this.$route, "this.$route");

    this.init();
    this.areaList = provincial.data;
    this.formInline.phone = this.phone || "";
  },
  methods: {
    ...mapActions({
      updatePhonenumber: "user/updatePhonenumber",
      validatePhone: "loginModule/validatePhone",
      graphqlPost: "graphqlPost",
      graphqlPostByZXS: "graphqlPostByZXS",
      getCategory: "getCategory",

      getMsgCode: "getMsgCode",
      validateCode: "validateCode",
      regUser: "regUser",
      regUserByVisitor: "regUserByVisitor",
      getExhibitionInfo: "loginModule/getExhibitionInfo",
      getToken: "loginModule/getToken",
    }),
    ...mapMutations({
      setLoginType: "setLoginType",
      setUser: "setUser",
      setUserId: "setUserId",
      setToken: "setToken",
      setTokenTime: "setTokenTime",
      setLogin: "setLogin",
      setExhibitionId: "setExhibitionId",
      setLoginBg: "setLoginBg",
    }),
    init () {
      if (this.user) {
        let city = [];
        if (this.user.province) {
          city.push(this.user.province);
          if (this.user.city) {
            city.push(this.user.city);
          }
          if (this.user.county) {
            city.push(this.user.county);
          }
        }
        this.formInline = {
          name: this.user.name || "",
          passWord: "",
          phone: this.user.phone || "",
          company: this.user.company,
          jobTitle: this.user.jobTitle,
          city: city,
          email: this.user.email,
          category: [],
        };
      }
      this.initCate();
    },
    async initCate () {
      let res = await this.getCategory();
      if (res && res.categories) {
        let ProCategory = JSON.parse(JSON.stringify(res.categories));
        ProCategory.map((c) => {
          c.value = c.name;
          c.label = c.name;
          if (c.childrens && c.childrens.length > 0) {
            c.children = c.childrens;
            c.children.map((v) => {
              v.value = v.name;
              v.label = v.name;
              if (v.childrens && v.ichildrens.length > 0) {
                v.children = v.childrens;
                v.children.map((z) => {
                  z.value = z.name;
                  z.label = z.name;
                });
              }
            });
          }
        });
        this.categoryList = ProCategory;
        let typeList = [];
        this.formInline.category = [];
        let array = [];
        if (this.user && this.user.inMember && this.user.inMember.category) {
          typeList = this.user.inMember.category.split(";");
          console.log(this.typeList, "革新type");
          typeList.forEach((c) => {
            if (c.indexOf(">")) {
              c = c.split(">");
            }
            array.push(c);
          });
        } else {
          typeList = [];
          array.push(typeList);
        }
        this.formInline.category = array;
      }
    },
    addCategoryArrr () {
      if (this.formInline.category.length >= 5) {
        this.$Message.warning(`最多添加5个展品类别`);
        return;
      }
      this.formInline.category.push([]);
      this.cateKey += 1;
    },
    delCategoryArrr (i) {
      console.log(i);
      this.cateKey += 1;
      this.formInline.category.splice(i, 1);
      this.$nextTick(() => {
        this.formInline.category = JSON.parse(JSON.stringify(this.formInline.category));
      });
    },
    changeCategoryArr () {
      setTimeout((c) => {
        this.$refs["formInline"].validateField("category");
      }, 400);
    },
    validForm (name) {
      // 验证表单
      return new Promise((resolve, reject) => {
        this.$refs[name].validate((valid) => {
          resolve(valid);
        });
      });
    },
    async validMsgCode () {
      // 验证码校验
      if (!this.formInline.code) return false;
      if (!this.codeKey) {
        this.$Message.warning("请获取验证码!");
        return false;
      }
      let valid = await this.validateCode({
        key: this.codeKey,
        code: this.formInline.code,
      });
      if (!valid) {
        this.$Message.error("验证码未通过!");
        return false;
      }
      return true;
    },
    async downTime () {
      const valid = await this.getMsgCode(this.formInline.phone);
      if (valid && valid.result) {
        this.codeKey = valid.data.key;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.showCode = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.showCode = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    async getCaptcha () {
      let phone = this.formInline.phone;
      if (!phone) {
        let txt = "";
        if (!txt) {
          txt = "请输入手机号码";
        } else if (/^[1][0-9]{10}$/.test(phone)) {
          txt = "";
        } else {
          txt = "请输入正确的手机号码";
        }
        if (txt) {
          this.$Message.error({ content: txt, duration: 2 });
        }
        return;
      }
      let res = await this.validatePhone(phone);
      if (res && res.data) {
        this.$Message.error({
          content: `手机号（${phone}）已绑定其他账号`,
          duration: 2,
        });
        return;
      } else {
        this.downTime();
      }
    },
    async handleSubmit (name) {
      let valid = await this.validForm(name); // 验证表单
      if (!valid) return;

      if (!this.phone) {
        // 默认账户没有手机号 需走一遍验证手机号的流程
        let setPhone = this.formInline.phone;
        if (!this.codeKey) {
          this.$Message.warning("请获取验证码!");
          return false;
        }

        let valid = await this.validateCode({
          key: this.codeKey,
          code: this.numCode,
        });
        if (!valid) {
          this.$Message.error("验证未通过!");
          return;
        }

        let res = await this.validatePhone(setPhone);
        if (res && res.data) {
          this.$Message.error({
            content: `手机号（${setPhone}）已绑定其他账号`,
            duration: 2,
          });
          return;
        }
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 5000);

        const req = {
          memberId: this.user.inMember.memberId,
          phonenNumber: setPhone,
        };

        let bool = await this.updatePhonenumber(req).catch((err) => {
          this.loading = false;
        });
        if (!bool.result) {
          // 修改手机失败
          this.$Message.error({
            content: `绑定手机号失败！`,
            duration: 2,
          });
          return;
        }
      }
      this.reg();
    },
    async reg () {
      let category = [];
      if (this.formInline.category.length > 0) {
        this.formInline.category.forEach((c) => {
          if (c.length > 1) {
            c = c.join(">");
          } else {
            c = c[0];
          }
          category.push(c);
        });
        this.formInline.category = category.join(";");
      }
      let body = {
        name: this.formInline.name,
        password: this.formInline.passWord,
        phone: this.formInline.phone,
        jobTitle: this.formInline.jobTitle,
        company: this.formInline.company,
        province: this.formInline.city[0] || "",
        city: this.formInline.city[1] || "",
        county: this.formInline.city[2] || "",
        exhibitionId: this.exhibitionId,
        email: this.formInline.email,
        isCover: this.isCover ? true : false,
        category: this.formInline.category,
        originType: this.regOrigin ? this.regOrigin.originType : "",
        originId: this.regOrigin ? this.regOrigin.originId : "",
        originRemark: this.regOrigin ? this.regOrigin.originRemark : "",
        originLang: "cn",
      };
      console.log(body, "---");
      let res = false;
      if (this.user && this.user.userId) {
        body = {
          inMemberId: this.user.inMember.id,
          exhibitorId: this.exhibitionId,
          name: this.formInline.name,
          jobTitle: this.formInline.jobTitle,
          company: this.formInline.company,
          province: this.formInline.city[0] || "",
          city: this.formInline.city[1] || "",
          county: this.formInline.city[2] || "",
          email: this.formInline.email,
          category: this.formInline.category,
          originType: this.regOrigin ? this.regOrigin.originType : "",
          originId: this.regOrigin ? this.regOrigin.originId : "",
          originRemark: this.regOrigin ? this.regOrigin.originRemark : "",
          originLang: "cn",
        };
        res = await this.regUserByVisitor(body);
      } else {
        res = await this.regUser(body);
      }
      if (res.result) {
        if (res.data) {
          this.code = res.data.code;
        }
        this.regFinish();
      } else {
        this.$Message.error(res.message);
      }
    },
    async regFinish () {
      if (this.login) {
        this.$router.push(`/regFinish/${this.exhibitionId}${this.code ? "?code=" + this.code : ""}`);
        return;
      }

      let orgin = location.host.split(".").reverse()[1];
      let clientId = orgin === "zhanshangxiu" ? "synair-showonline-js" : `synair-${orgin}-js`;
      let opt = {
        client_id: clientId,
        client_secret: "synair-showonline-js",
        grant_type: "phone_number",
        scope: "openid profile showonline-api bmm-api phone",
        phone_number: this.formInline.phone,
      };
      let tokenData = await this.getToken(opt);
      if (tokenData) {
        this.setToken(tokenData.access_token);
        this.setTokenTime(tokenData.expires_in * 1000 + new Date().getTime());
        this.setExhibitionId(this.exhibitionId);
        await this.getExhibitionInfo(this.exhibitionId);
        this.setLoginType(1);
        this.getUser();
      }
    },

    async getUser () {
      let query = `
        query{
          memberQuery{
            current{
              address
              avatarUrl
              balance
              birthday
              businessCard
              city
              company
              country
              county
              createAt
              deleteAt
              department
              email
              followCount
              id
              inMember(exhibitionId:"${this.exhibitionId}"){ 
                business
                category
                id 
                memberId
                originId
                originLang
                originRemark
                isBlacklist
                originType
                regAt
                sayCount
                sayUserCount
                userId
                userRole
                exhibitorId
                companyUsers{
                  id
                  isAppointment
                }
              }
              introduction
              isDeleted
              jobTitle
              name
              nickName
              phone
              phoneConfirm
              province
              talkCount
              talkSuccessedCount
              title
              updateAt
              userId
            }
          } 
        }
      `;
      let opt = {
        opt: {
          query: query,
        },
        token: this.token,
      };
      let data = await this.graphqlPost(opt);
      if (data.data && data.data.memberQuery && data.data.memberQuery.current) {
        this.setLogin(true);
        this.setUser(data.data.memberQuery.current);
        this.setUserId(data.data.memberQuery.current.userId);
        this.$router.push(`/regFinish/${this.exhibitionId}${this.code ? "?code=" + this.code : ""}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/scss/my-theme/mixin.scss";
.reg {
  &-header {
    box-shadow: 0px 2px 3px 0px rgba(101, 101, 101, 0.1);
  }
  &-main {
    width: 1200px;
    margin: 50px auto;
    .m-steps {
      width: 710px;
      margin: 0 auto;
      ::v-deep .ivu-steps-item {
        .ivu-steps-head {
          background: transparent;
        }
      }
    }
  }
  .F {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 380px;
    position: relative;
    margin: 0 auto;
    &-1 {
      margin-top: 34px;
      margin-bottom: 0;
      &.phone_code {
        ::v-deep {
          .ivu-form-item-content {
            display: flex;
            .ivu-btn {
              margin-left: 10px;
            }
          }
        }
      }
      .I {
        // margin-top: 19px;
      }
    }
    .F-2 {
      width: 100%;
      height: 15px;
      line-height: 15px;
      display: flex;
      justify-content: space-between;
      margin: 24px 0;
      .F-2-text {
        width: 48px;
        height: 13px;
        font-size: 12px;
        color: #333;
        cursor: pointer;
      }
    }
    .wx-btn {
      margin-top: 20px;
    }
    .code {
      width: 70px;
      height: 26px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      cursor: pointer;
    }
    .btn {
      margin: 34px auto 0;
    }
  }
}
.admin-add {
  margin-top: 34px;
}
.service-code {
  position: relative;
  width: 300px;
  .code-btn1 {
    position: absolute;
    top: 4px;
    right: 7px;
    height: 28px;
    padding: 0;
    @include font_color(#1890ff);
  }
  .code-btn2 {
    position: absolute;
    top: 4px;
    right: 7px;
    height: 28px;
    padding: 0;
  }
}
</style>
